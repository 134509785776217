<script>
import audioLibroIcon from "./icono_audio_libro.png";

export default {
	lang: 'account',
	props: {
		userContentFiles: Array,
		canShare: Boolean,
		noFilesMessage: String
	},
	data() {
		return {
			bookPreviewDialog: false,
			selectedUserContentFile: null,
			loadingDownload: {},
			audioLibroIcon
		}
	},
	methods: {
		showBook(userContentFile) {
			this.selectedUserContentFile = userContentFile
			this.bookPreviewDialog = true;
		},
		async downloadFile(userContentFile) {
			this.$set(this.loadingDownload, userContentFile.id, true);

			const response = await fetch(this.makeFileUrl(userContentFile));
			const blob = await response.blob();
			const blobURL = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.setAttribute('href', blobURL);
			link.setAttribute('download', userContentFile.realName);
			link.style.display = 'none';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			this.$set(this.loadingDownload, userContentFile.id, false);
		},
		makeFileUrl(userContentFile) {
			const { filename, filetype } = userContentFile;
			return this.$shopApi.makeUrl(`/catalog/user-content-files/${filename}?mimeType=${filetype}`);
		},
		handleScrollAndZoom(v) {
			if (v) {
				document.querySelector('html').style.overflow = 'hidden'
				document.querySelector('[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1,minimum-scale=1')
			}
			else {
				document.querySelector('html').removeAttribute('style')
				document.querySelector('[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1')
			}
		}
	},
	watch: {
		bookPreviewDialog(v) {
			this.handleScrollAndZoom(v)
		}
	},
	beforeDestroy() {
		this.handleScrollAndZoom(false)
	}
}
</script>

<template>
	<div>
		<template v-if="userContentFiles.length">
			<div v-for="(userContentFile, i) of userContentFiles" :key="i"
				class="d-flex flex-md-row align-md-center flex-column mb-6 mb-md-2">
				<div class="d-flex align-center">
					<div style="width: 100px; min-width: 100px; height: 100px"
						class="rounded-lg grey lighten-2 d-flex align-center justify-center overflow-hidden mr-2">

						<img v-if="userContentFile.type == 'book'" :src="userContentFile.preview"
							style="width: 100%; object-fit: contain;" />

						<img v-else-if="userContentFile.filetype.includes('image')" :src="makeFileUrl(userContentFile)"
							style="width: 100%; object-fit: contain;" />

						<video v-else-if="userContentFile.filetype.includes('video')" :src="makeFileUrl(userContentFile)"
							style="width: 100%;" frameborder="0">
						</video>
						<div v-else-if="userContentFile.filetype.includes('pdf')">
							<v-icon x-large>
								mdi-file-pdf-box
							</v-icon>
						</div>
						<div v-else-if="userContentFile.filetype.includes('audio')"
							class="overflow-hidden d-flex justify-center align-center">
							<img :src="audioLibroIcon" style="width: 105%; height: 105%" />
						</div>
					</div>
					<div class="flex-grow-1">
						<div class="font-2"
							:style="{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: $vuetify.breakpoint.mdAndUp ? '250px' : '150px' }">
							{{ userContentFile.realName }}
						</div>
						<div class="grey--text">
							{{ userContentFile.type === 'book' ? 'Libro' : userContentFile.filetype }}
						</div>
					</div>
				</div>
				<v-spacer />
				<div class="pt-2">
					<Button color="green" v-if="userContentFile.type === 'book'" @click="showBook(userContentFile)" block>
						<v-icon left>mdi-eye</v-icon>
						Ver Libro
					</Button>
					<Button v-else color="green" @click="downloadFile(userContentFile)" block
						:loading="loadingDownload[userContentFile.id]">
						<v-icon left>mdi-download</v-icon>
						Descargar
					</Button>
					<v-menu offset-y v-if="userContentFile.filetype.includes('audio')">
						<template v-slot:activator="{ on, attrs }">
							<Button color="green" block v-on="on" v-bind="attrs" class="mt-2">
								<v-icon left>mdi-play</v-icon>
								Reproducir
							</Button>
						</template>
						<v-card class="pa-4">
							<audio :src="makeFileUrl(userContentFile)" controls></audio>
						</v-card>
					</v-menu>
					<ShareContentFileBtn v-if="canShare" :user-content-file="userContentFile" class="mt-2" />
				</div>
			</div>
		</template>
		<div v-else-if="!userContentFiles.length" class="d-flex justify-center py-8">
			{{ noFilesMessage || $lang('No hay archivos') }}
		</div>

		<UserContentFiles-BookPreviewDialog v-if="bookPreviewDialog" v-model="bookPreviewDialog"
			:user-content-file="selectedUserContentFile" />
	</div>
</template>