<script>
export default {
	props: {
		userContentFile: Object
	},
	data() {
		return {
			loading: false,
			snackbar: false
		}
	},
	methods: {
		fallbackCopyTextToClipboard(text) {
			var textArea = document.createElement("textarea");
			textArea.value = text;

			textArea.style.top = "0";
			textArea.style.left = "0";
			textArea.style.position = "fixed";

			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';
				document.body.removeChild(textArea);
				return Promise.resolve('Fallback: Copying text command was ' + msg);
			} catch (err) {
				document.body.removeChild(textArea);
				return Promise.reject(err);
			}
		},
		copyTextToClipboard(text) {
			if (!navigator.clipboard) return this.fallbackCopyTextToClipboard(text);
			return navigator.clipboard.writeText(text)
		},
		copyUrl() {
			this.$shopApi.get({
				url: '/catalog/user-content-files/get-share-hash/' + this.userContentFile.id,
				loading: (v) => this.loading = v,
				onSuccess: async ({ data }) => {
					const { href } = this.$router.resolve({
						name: 'shared.content-files',
						params: {
							hash: data.hash
						}
					})
					await this.copyTextToClipboard(window.location.origin + href)
					this.snackbar = true
				}
			})
		}
	}
}
</script>

<template>
	<div>
		<Button color="orange" :loading="loading" @click="copyUrl">
			<v-icon>
				mdi-share-variant
			</v-icon>
			Compartir
		</Button>
		<v-snackbar v-if="snackbar" v-model="snackbar" large top right>
			Url copiada al portapapeles
			<template v-slot:action="{ attrs }">
				<v-btn color="green" text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>