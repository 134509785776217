<script>

export default {
	lang: 'account',
	props: {
		value: Boolean,
		userContentFile: Object,
	},
	model: {
		prop: 'value',
		event: 'input',
	},
	data() {
		return {
			loading: false,
			selectedUserContentFile: null,
			orders: [],
			e: {
				loading: {
					data: true,
				},
				error: false,
				customization: {
					data: {},
				},
				customizationFiles: {},
				config: {},
				editing: false,
				bookData: null,
				bookImgs: null,
				validation: {},
				dataChanged: false,
				dataCompleted: false,
				previewReady: false,
				firstPreviewReady: false,
				pageSize: null,
				dialog: false,
				tab: -1,
			},
			scale: 1,
			paddingTop: 0,
			count: 0
		}
	},
	provide() {
		return {
			editor: () => this.e,
		}
	},
	computed: {
		show() {
			return {
				error: this.e.error,
				loader: !this.e.error && !this.e.previewReady,
				preview: !this.e.error && !this.e.loading.data,
			}
		},
		transform() {
			return `scale(${this.scale})`
		},
		scalableContainerStyle() {
			return {
				transform: this.transform,
				transformOrigin: 'left top',
				pointerEvents: 'none'
			};
		},
		mainContainerStyle() {
			return {
				width: '100%',
				height: '100%',
				paddingTop: `${this.paddingTop}px`,
				paddingBottom: !this.isMobile ? 0 : '65px',
			}
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		}
	},
	methods: {
		loadBookCustomization(orderItemId) {
			try {
				return this.$shopApi.get({
					url: `/book-customization-by-order-item/${orderItemId}`,
					loading: (v) => this.e.loading.data = v,
					done: ({ data, success, message }) => {
						if (success) {
							this.e.customization = {}
							this.$assignDeep(this.e, data)
							this.e.customizationFiles = {}
						} else {
							if (message?.code == 'item_not_found') {
								this.$router.replace({ name: 'home' })
							} else {
								this.e.error = true
							}
						}
					},
				})
			} catch (err) {
				console.log(err)
			}
		},
		onPreviewReady() {
			this.e.previewReady = true
			this.e.firstPreviewReady = true
			this.e.loading.submit = false
			this.e.dataChanged = false
			this.$nextTick(this.calcPaddingTop)
		},
		nextPage() {
			// this.e.pageFlip?.flipNext()
			this.e.carouselBook?.moveNext()
		},
		prevPage() {
			// this.e.pageFlip?.flipPrev()
			this.e.carouselBook?.movePrev()
		},
		calcPaddingTop() {
			let realHeight = this.e.pageSize.h * this.scale;
			this.paddingTop = Math.max((window.innerHeight - realHeight) / 2, this.isMobile ? 50 : 0)
		}
	},
	watch: {
		async scale() {
			this.$nextTick(() => {
				const scroll1 = {
					left: this.$refs.overflow1.scrollLeft,
					top: this.$refs.overflow1.scrollTop
				}
				const scroll2 = {
					left: this.$refs.overflow2.scrollLeft,
					top: this.$refs.overflow2.scrollTop
				}
				this.count++
				this.$nextTick(() => {
					if (this.count % 2 == 0) {
						this.$refs.overflow1.scrollLeft = scroll2.left
						this.$refs.overflow1.scrollTop = scroll2.top
					} else {
						this.$refs.overflow2.scrollLeft = scroll1.left
						this.$refs.overflow2.scrollTop = scroll1.top
					}

					this.calcPaddingTop()

					if (!this.isMobile) {
						let { innerWidth } = window;
						let scrollWidth = innerWidth * this.scale;

						let realSize = this.e.pageSize.w * this.scale;
						let padding = 0;

						if (realSize <= innerWidth) {
							padding = (innerWidth - realSize) / 2
						}

						requestAnimationFrame(() => {
							if (this.count % 2 == 0) {
								this.$refs.overflow1.scrollLeft = (scrollWidth / 2) - (realSize / 2) - padding
							} else {
								this.$refs.overflow2.scrollLeft = (scrollWidth / 2) - (realSize / 2) - padding
							}
						})
					}
				})
			})

		}
	},
	created() {
		const [orderItemId] = this.userContentFile.filename.split('||');
		this.loadBookCustomization(orderItemId)
	},

}
</script>

<template>
	<v-dialog :value="value" @input="e => $emit('input', e)" max-width="100%" fullscreen>
		<div class="lightyellow relative" :style="mainContainerStyle">
			<div class="lightyellow" :style="{ width: '100%', height: '100%' }"
				:class="{ 'overflow-auto': count % 2 == 0 }" ref="overflow1">
				<div class="lightyellow" :style="{ width: '100%', height: '100%' }"
					:class="{ 'overflow-auto': count % 2 == 1 }" ref="overflow2">

					<div class="d-flex absolute-top-right"
						v-if="show.preview && !show.loader && $vuetify.breakpoint.mdAndUp">
						<Button fab :small="$vuetify.breakpoint.smAndDown" elevation="0" color="darkblue"
							@click="$emit('input', false)">
							<v-icon large>mdi-chevron-down</v-icon>
						</Button>
					</div>
					<div class="d-flex align-center justify-center w100 py-4 font-3 error--text px-16"
						style="min-height: 350px" v-if="show.error">
						<div class="d-flex flex-column justify-center align-center">
							<div>
								{{ $lang('Ha ocurrido un error al procesar la previsualización del libro.') }}
							</div>
							<Button class="mt-4" color="info" large @click=" $emit('input', false)">
								{{ $lang('Volver') }}
							</Button>
						</div>
					</div>
					<div class="overflow-hidden d-flex align-center" style="height: 100%;" v-if="show.loader">
						<BookPreviewBallsLoader class="mb-4" ref="ballsLoader" />
					</div>
					<div :style="scalableContainerStyle"
						:class="{ 'book-page-img-desktop': !isMobile, 'book-page-img-mobile': isMobile }">
						<BookPreview @ready="onPreviewReady" ref="bookPreview" v-if="show.preview" full-width use-slider
							disable-on-init />
					</div>
					<div class="green pa-2 rounded-xl mr-2 d-flex align-center controls" :class="{
						'controls-mobile': $vuetify.breakpoint.smAndDown,
						'controls-desktop': $vuetify.breakpoint.mdAndUp
					}" v-if="show.preview && !show.loader">
						<Button fab dark small outlined @click="scale -= (scale === 1 ? 0 : 0.1)">
							<v-icon>mdi-magnify-minus-outline</v-icon>
						</Button>
						<div class="white--text d-flex justify-center mx-2" style="min-width: 50px">
							<b>{{ scale.toFixed(1) }}x</b>
						</div>
						<Button fab dark small class="mr-4" outlined @click="scale += 0.1">
							<v-icon>mdi-magnify-plus-outline</v-icon>
						</Button>
						<Button fab dark small outlined @click="prevPage">
							<v-icon>mdi-chevron-left</v-icon>
						</Button>
						<div class="white--text d-flex justify-center mx-2" style="min-width: 55px;">
							<b>{{ (e.carouselBookCurrentIndex || 0) + 1 }}</b>/<b>{{ e.carouselBookItemsLength }}</b>
						</div>
						<Button fab dark small outlined @click="nextPage">
							<v-icon>mdi-chevron-right</v-icon>
						</Button>
						<Button fab small elevation="0" color="darkblue" class="ml-1" v-if="$vuetify.breakpoint.smAndDown"
							@click="$emit('input', false)">
							<v-icon large>mdi-chevron-down</v-icon>
						</Button>
					</div>

				</div>
			</div>
		</div>
	</v-dialog>
</template>

<style scoped>
.absolute-top-right {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 1;
}

::v-deep .page-count {
	display: none;
}

.book-page-img-desktop ::v-deep .page-wrapper img {
	height: calc(100% + 14.82mm) !important;
	top: -7.4mm;
	left: -7.4mm;
}

.book-page-img-mobile ::v-deep .page-wrapper img {
	height: calc(100% + 7.41mm) !important;
	top: -3.2mm;
	left: -3.2mm;
}

.controls {
	position: absolute;
}

.controls-mobile {
	top: 5px;
	left: 50%;
	transform: translateX(-50%);
}

.controls-desktop {
	right: 66px;
	top: 5px;
}
</style>