<script>
export default {
	lang: 'account',
	pageView: true,
	data() {
		return {
			loading: false,
			loadingMore: false,
			userContentFiles: [],
			pagination: {
				page: 1
			},
		}
	},
	methods: {
		async fetchData(loading) {
			console.log('fetch data')
			await this.$shopApi.get({
				url: '/catalog/user-content-files',
				loading,
				query: {
					itemsPerPage: 5,
					page: this.pagination.page
				},
				onSuccess: ({ data }) => {
					this.userContentFiles = [
						...this.userContentFiles,
						...(data.items || [])
					]
					this.$assignDeep(this.pagination, data.pagination)
				}
			})
		},
		loadMore() {
			this.pagination.page++;
			this.fetchData((v) => this.loadingMore = v)
		},
	},
	created() {
		this.fetchData((v) => this.loading = v)
	},
}
</script>

<template>
	<UserpanelLayout :title="$lang('Biblioteca')">
		<div class="d-flex justify-center py-8" v-if="loading">
			<v-progress-circular :size="60" :width="6" color="purple" indeterminate></v-progress-circular>
		</div>


		<UserContentFilesDisplay v-else :no-files-message="$lang('No tienes archivos en tu biblioteca')" can-share
			:user-content-files="userContentFiles" />

		<div class="d-flex justify-center py-4">
			<Button color="orange" @click="loadMore" :loading="loadingMore" v-if="userContentFiles.length"
				:disabled="pagination.lastPage == pagination.page">
				<v-icon left>mdi-reload</v-icon>
				Cargar más
			</Button>
		</div>
	</UserpanelLayout>
</template>


<style scoped>
.absolute-top-right {
	position: absolute;
	top: 5px;
	right: 5px;
	z-index: 1;
}

.v-menu__content {
	max-width: 100% !important;
}
</style>
