<script>
export default {
	data() {
		return {
			userContentFiles: [],
			loading: false
		}
	},
	created() {
		const hash = this.$route.params.hash;
		this.$shopApi.get({
			url: '/catalog/user-content-files/get-by-hash/' + hash,
			loading: v => this.loading = v,
			onSuccess: ({ data }) => {
				this.userContentFiles = [data.userContentFile]
			}
		})
	}
}
</script>

<template>
	<Container class="py-16">
		<div class="text-h4">
			Archivos compartidos
		</div>
		<v-divider class="my-4"></v-divider>
		<div class="d-flex justify-center py-8" v-if="loading">
			<v-progress-circular :size="60" :width="6" color="purple" indeterminate></v-progress-circular>
		</div>
		<UserContentFilesDisplay :user-content-files="userContentFiles" v-else />
	</Container>
</template>